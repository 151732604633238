<template>
  <div
    class="row"
  >
    <div class="col-12 col-xl-8 col-xxl-6">
      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Grunddaten
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-input
            v-model="hsntsn.hsn"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="HSN"
            placeholder="HSN"
            required
          />

          <basic-input
            v-model="hsntsn.tsn"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="TSN"
            placeholder="TSN"
            required
          />

          <basic-input
            v-model="hsntsn.typ"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Typ"
            placeholder="Typ"
          />

          <basic-input
            v-model="hsntsn.producer"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Hersteller"
            placeholder="Hersteller"
            required
          />

          <basic-input
            v-model="hsntsn.bj_start"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Baujahr Start"
            placeholder="Baujahr Start"
            required
          />

          <basic-input
            v-model="hsntsn.bj_end"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Baujahr Ende"
            placeholder="Baujahr Ende"
          />
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Fahrzeugdaten
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-input
            v-model="hsntsn.ccm"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Hubraum"
            placeholder="Hubraum"
            required
          />

          <basic-input
            v-model="hsntsn.kw"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="kW"
            placeholder="kW"
            required
          />

          <basic-input
            v-model="hsntsn.ps"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="PS"
            placeholder="PS"
          />

          <basic-input
            v-model="hsntsn.wfs"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="WFS"
            placeholder="WFS"
          />
        </template>
      </ca-card>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import CaCard from '@/components/Card'
export default {
  components: {
    BasicInput,
    CaCard
  },
  props: {
    hsntsn: {
      type: Object,
      required: true
    }
  },
  created: function () {
    if (!isNaN(this.hsntsn.kw)) {
      this.hsntsn.kw = parseInt(this.hsntsn.kw, 10)
    }
    if (!isNaN(this.hsntsn.ccm)) {
      this.hsntsn.ccm = parseInt(this.hsntsn.ccm, 10)
    }
  }
}
</script>

<style>

</style>
